<template>
    <div class="responseCard" :class="pos < 4 ? 'doover' : ''">
        <div class="music">
            <img v-if="!!chanson.album" :src="chanson.album.images[1].url" alt="img">
            <img v-if="!!!chanson.album" :src="chanson.images[1].url" alt="img">
        </div>
        <div class="data">
            <a :href=chanson.uri class="titre">{{chanson.name}}</a>
            <p class="artiste">{{chanson.artists[0].name}}</p>
        </div>
        <div class="action">
            <button class="search" v-on:click="addToSearch"><span class="material-symbols-outlined">search</span></button>
            <!-- <button class="like" :class="{ open: !isSongLiked }" v-on:click="addToLike"><span class="material-symbols-outlined">search</span></button>
            <button class="unlike" :class="{ open: isSongLiked }" v-on:click="removeToLike"><span class="material-symbols-outlined">favorite</span></button> -->
            <div v-if="chanson.preview_url" class="controls">
                <button v-if="status" v-on:click="play" class="play"><span class="material-symbols-outlined">play_circle</span></button>
            </div>
        </div>
    </div>
</template>

<script>
// import DcoverAPI from "../../src/api/dcoverAPI";

export default {
    name: 'responseCard',
    props: ['chanson', 'pos'],
    data() {
        return {suggestion: [], status: true, isSongLiked: false}
    },
    mounted() {
        const likedPlaylist = localStorage.getItem('lovedPlaylist')
        if(likedPlaylist.includes(this.chanson.id)) {
            this.isSongLiked = true;
        }
    },
    methods: {
        play() {
            this.$emit('currentlyPlayed', this.chanson);
        },
        addToSearch() {
            this.$emit('addToSearch', this.chanson);
        },
        // addToLike() {
        //     const dcoverAPI = new DcoverAPI();
        //     dcoverAPI.addToLike(this.chanson)
        //     this.isSongLiked = !this.isSongLiked
        // },
        // async removeToLike() {
        //     const dcoverAPI = new DcoverAPI();
        //     await dcoverAPI.removeToLike(this.chanson)
        //     this.isSongLiked = !this.isSongLiked
        //     this.$emit('removedSong', this.pos)
        // }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.responseCard {
    /* border: solid 1px salmon; */
    display: flex;
    width: 45%;
    height: 100px;
    justify-content: space-between;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: left;
    background: linear-gradient(90deg, #dddddc 0%, #f3f3f3 100%);
    
    @media screen and (max-width: 1300px) {
        width: 90%;
    }
}
.responseCard.doover {
    background: linear-gradient(90deg, #f7a586 0%, #fce6dd 100%);
}
.music {
    height: 100%;
    aspect-ratio: 1 / 1;
}
.data {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-transform: uppercase;
    padding: 0px 5px;
}
.data a {
    text-decoration: none;
    font-weight: 700;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.data p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
img {
    height: 100%;
    aspect-ratio: 1 / 1;
}
.action {
    display: flex;
    background-color: black;
    height: 100%;
    padding: 3px;
    flex-direction: column;
    justify-content: space-between;

    span {
        cursor: pointer;
    }

    .like, .unlike, .search {
        height: 25px;
        margin-top: 5px;
        background: none;
        border: none;
        outline: none;
        border-bottom: solid 1px white;
    }
}
.doover .action {
    background-color: #f25b22;
}
.action button.play {
    height: 25px;
    margin-bottom: 5px;
    background: none;
    border: none;
    outline: none;
}
.material-symbols-outlined {
    color: white;
}
.unlike .material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.like.open {
    display: block;
}
.unlike.open {
    display: block;
}
.like {
    display: none;
}
.unlike {
    display: none;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .responseCard {
        width: 100%;
        height: 80px;
    }
}

</style>