import axios from 'axios'
import {
    Client
} from 'spotify-sdk';

class dcoverAPI {

    setCookie(cname, cvalue, ctime) {
        const d = new Date();
        ctime = ctime ?? (1 * 3600 * 1000);
        d.setTime(d.getTime() + ctime);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    supprCookie(cname) {
        const d = new Date();
        d.setTime(d.getTime() - (1 * 3600 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "='';" + expires + ";path=/";
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }

    async login() {
        const token = window.location.hash.split('&')[0].split('=')[1];
        if (!this.getCookie('token') && token == null) {
            const client_id = '55971b5193b147a8a438659c18ae9fff'; // Your client id
            const redirect_uri = location.origin + '/callback.html'; // Your redirect uri

            const SECRET_ID = "ac66547861454522a463116eb83f7ef8"

            const client = Client.instance;
            client.settings = {
                clientId: client_id,
                secretId: SECRET_ID,
                scopes: ['playlist-modify-public playlist-modify-private playlist-read-private playlist-read-collaborative user-modify-playback-state user-read-recently-played user-library-read'],
                redirect_uri: redirect_uri
            };
            client.login().then((url) => {
                window.location.href = url;
            });
        }
    }

    logout() {
        this.supprCookie('token');
        location.href = 'https://www.spotify.com/logout/';
    }

    async getUserInfo() {
        const token = this.getCookie('token');
        let temp;
        if(token) {
            await axios.get('https://api.spotify.com/v1/me', {
                headers: {
                    Authorization: 'Bearer ' + token //the token is a variable which holds the token
                }
            }).then(res => {
                localStorage.setItem("userId", res.data.id);
            })
        }
        return temp
    }

    async search(search) {
        const token = this.getCookie('token');
        let temp;
        await axios.get('https://api.spotify.com/v1/search?q=' + search + '&type=track&limit=6', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(res => {
            temp = res.data.tracks.items.splice(0, 5)
        })
        return temp
    }

    async getSimilar(tracks) {
        const token = this.getCookie('token');
        let temp;
        let tracksID;

        for (let index = 0; index < tracks.length; index++) {
            const element = tracks[index];
            if(index == 0) {
                tracksID = element.id
            } else {
                tracksID = tracksID+','+element.id
            }
        }
        await axios.get('https://api.spotify.com/v1/recommendations?seed_tracks=' + tracksID , {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(res => {
            temp = res.data.tracks
        })
        return temp
    }

    async getRandom() {
        const lovedID = localStorage.getItem('lovedID');
        let url;
        if (lovedID) {
            url = `https://api.spotify.com/v1/playlists/${lovedID}/tracks`
        } else {
            //make a variable with some search queries and put it in an array. (you can create more search queries.
            // const getRandomSongsArray = ['%25a%25', 'a%25', '%25e%25', 'e%25', '%25i%25', 'i%25', '%25o%25', 'o%25'];
            const getRandomSongsArray = ['%25a%25', 'a%25', '%25b%25', 'b%25', '%25c%25', 'c%25', '%25d%25', 'd%25', '%25e%25', 'e%25', '%25f%25', 'f%25', '%25g%25', 'g%25', '%25h%25', 'h%25', '%25i%25', 'i%25', '%25j%25', 'j%25', '%25k%25', 'k%25', '%25l%25', 'l%25', '%25m%25', 'm%25', '%25n%25', 'n%25', '%25o%25', 'o%25', '%25p%25', 'p%25', '%25q%25', 'q%25', '%25r%25', 'r%25', '%25s%25', 's%25', '%25t%25', 't%25', '%25u%25', 'u%25', '%25v%25', 'v%25', '%25w%25', 'w%25', '%25x%25', 'x%25', '%25y%25', 'y%25', '%25z%25', 'z%25'];
    
            //This will get a random result out of the array above
            const getRandomSongs = getRandomSongsArray[Math.floor(Math.random() * getRandomSongsArray.length)];
    
            //This will get a random offset number between 1 and 1000. So you get a random track. (you can change the numbers btw)
            const getRandomOffset = Math.floor(Math.random() * 100);
    
            //This is the url that gets the results out of the Spotify API. You have to put in the variables you created above.
            url = 'https://api.spotify.com/v1/search?query=' + getRandomSongs + '&offset=' + getRandomOffset + '&limit=10&type=album&tag:hipster';
        }

        const token = this.getCookie('token');
        let temp = [], lovedPlaylist = [];
        localStorage.removeItem('lovedPlaylist')
        if (token) {
            await axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + token //the token is a variable which holds the token
                }
            }).then(res => {
                if (lovedID) {
                    let reponse = res.data.items;
                    for (let index = 0; index < reponse.length; index++) {
                        const element = reponse[index];
                        temp[index] = element.track;
                        lovedPlaylist[index] = element.track.id;
                    }
                    localStorage.setItem('lovedPlaylist', lovedPlaylist+',')
                } else {
                    temp = res.data.albums.items
                }
            })
        }
        return temp
    }

    async loadUserPlaylist(id) {
        const token = this.getCookie('token');
        let url = `https://api.spotify.com/v1/playlists/${id}/tracks`, temp = [];
        
        if (token) {
            await axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + token //the token is a variable which holds the token
                }
            }).then(res => {
                let reponse = res.data.items;
                for (let index = 0; index < reponse.length; index++) {
                    const element = reponse[index];
                    temp[index] = element.track;
                }
            })
        }
        return temp;
    }

    async getUserPlaylist() {
        const token = this.getCookie('token');
        const userId = localStorage.getItem("userId");
        const endpoint_url = `https://api.spotify.com/v1/users/${userId}/playlists?limit=50`;
        let playlist = [], res = [];
        const headers = {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
        if(token) {
            try {
                res = await this.makeApiCall(endpoint_url, {}, headers)
                if (localStorage.getItem('lovedID')) {
                    localStorage.removeItem("lovedID");
                }
                for (let index = 0; index < res.length; index++) {
                    const element = res[index];
                    if (element.name.includes('Doover -')) {
                        playlist.push(element);
                    }
                }
            } catch(e) {
                console.log(e)
            }
        }
        return playlist
    }

    async getLovedPlaylist() {
        const token = this.getCookie('token');
        let lovedSongs  = [];
        if(token) {
            try {
                const response = await axios.get('https://api.spotify.com/v1/me/tracks', {
                  headers: {
                    Authorization: 'Bearer ' + token
                  },
                  params: {
                    limit: 30
                  }
                });
                response.data.items.forEach(item => {
                    lovedSongs.push(item.track);
                });

              } catch (error) {
                console.error('Erreur lors de la récupération des pistes:', error.response ? error.response.data : error.message);
              }
        }
        return lovedSongs
    }

    async addToLike(value) {
        const token = this.getCookie('token');
        const userId = localStorage.getItem("userId");
        const lovedIDPlaylist = localStorage.getItem('lovedID')
        const lovedPlaylist = localStorage.getItem('lovedPlaylist')
        
        if (lovedIDPlaylist) {
            this.addMusicToPlaylist([value], [], lovedIDPlaylist)
            localStorage.setItem('lovedPlaylist', lovedPlaylist+value.id+',')
        } else {
            const endpoint_url = `https://api.spotify.com/v1/users/${userId}/playlists`;
            const request_body = {
                "name": "Doover - Loved",
                "description": "Your best of Doover",
                "public": false
            };
            const headers = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            axios.post(endpoint_url, request_body, {
                headers
            })
            .then(response => {
                if (!lovedIDPlaylist) {
                    this.addImageToPlaylist(response.data.id, '../../public/img_playlist.jpeg');
                }
                this.addMusicToPlaylist([value], [], response.data.id)
                localStorage.setItem('lovedID', response.data.id)
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    async removeToLike(chanson) {
        const token = this.getCookie('token');
        const lovedIDPlaylist = localStorage.getItem('lovedID')
        const lovedPlaylist = localStorage.getItem('lovedPlaylist')
        
        const endpoint_url = `https://api.spotify.com/v1/playlists/${lovedIDPlaylist}/tracks`;

        const request_body = {
            "tracks": [
                {
                    "uri": chanson.uri
                }
            ],
        };
        axios.delete(endpoint_url, {data: request_body, headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }})
        .then(() => {
            let newLovedPlaylist = lovedPlaylist+",";
            newLovedPlaylist = newLovedPlaylist.replace(String(chanson.id)+',', '!');
            localStorage.setItem('lovedPlaylist', newLovedPlaylist)
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
    }

    async createPlaylist(currentSong, musicList) {
        const token = this.getCookie('token');
        const userId = localStorage.getItem("userId");
        const endpoint_url = `https://api.spotify.com/v1/users/${userId}/playlists`;
    
        const request_body = {
            "name": "Doover - " + currentSong[0].name,
            "description": "New playlist description",
            "public": false
        };
    
        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        };
    
        try {
            const response = await axios.post(endpoint_url, request_body, { headers });
            // this.addImageToPlaylist(response.data.id, currentSong[0].album.images[1].url);
            this.addMusicToPlaylist(currentSong, musicList, response.data.id);
            return response.data.uri;
        } catch (error) {
            console.error('Error:', error);
            return false; // or handle error appropriately
        }
    }

    async addMusicToPlaylist(currentSong, musicList, id) {
        const token = this.getCookie('token');
        const endpoint_url = `https://api.spotify.com/v1/playlists/${id}/tracks`;
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        let trackURI = [];
        for (let index = 0; index < currentSong.length; index++) {
            const element = currentSong[index];
            trackURI.push(element.uri)
        }
        for (let index = 0; index < musicList.length; index++) {
            const element = musicList[index];
            trackURI.push(element.uri)
        }
        const requestBody = {
            uris: trackURI,
            position: 0,
        };
        axios.post(endpoint_url, requestBody, {
                headers
            })
            .then(() => {
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    async addImageToPlaylist(id, image) {
        try {
            const base64Image = await this.encodeImageToBase64(image);
            const endpoint_url = `https://api.spotify.com/v1/playlists/${id}/images`;
            const token = this.getCookie('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'image/jpeg',
            };

            await axios.put(endpoint_url, base64Image.split(',')[1], {
                headers
            });
            return true;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }

    encodeImageToBase64(imageUrl) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.crossOrigin = 'anonymous';
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);

                const base64String = canvas.toDataURL('image/jpeg');
                resolve(base64String);
            };

            img.onerror = function () {
                reject(new Error('Failed to load the image.'));
            };

            img.src = imageUrl;
        });
    }

    addPlaylistToQueue(currentSong, playlist) {
        let queue = []
        queue.push(currentSong[0].uri)
        for (let index = 0; index < playlist.length; index++) {
            const element = playlist[index];
            queue.push(element.uri)
        }

        const token = this.getCookie('token');
        const endpoint_url = `https://api.spotify.com/v1/me/player/queue`;

        for (let index = 0; index < queue.length; index++) {
            const element = queue[index];
            try {
                axios.post(endpoint_url, null, {
                    params: {
                        uri: element
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            } catch (error) {
                console.error('Error:', error);
                return false; // or handle error appropriately
            }
        }

    }

    async getUserRecentPlayed() {
        const token = this.getCookie('token');
        let temp = [];
        if(token) {
            await axios.get('https://api.spotify.com/v1/me/player/recently-played', {
                params: {
                    limit: 7
                },
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                }
            }).then(res => {
                res.data.items.forEach(item => {
                    temp.push(item.track);
                });
            })
        }
        return temp
    }

    async makeApiCall(url, request_body = {}, headers = {}) {
        let allData = []; // Tableau pour stocker toutes les données paginées.
        
        while (url) {
            
            const response = await axios({
                method: 'get', // Utilisez la méthode GET pour les appels paginés.
                url: url,
                data: request_body,
                headers: headers
            });
    
            // Ajoutez les données de cette réponse à la liste globale.
            if (response.data.items && Array.isArray(response.data.items)) {
                allData = allData.concat(response.data.items);
            }

            // Si la réponse contient une URL "next", continuez à paginer.
            if (response.data.next) {
                url = response.data.next;
            } else {
                url = null; // Aucune page suivante, arrêtez la pagination.
            }
        }
        return Object.values(allData);
    }
}

export default dcoverAPI;