<template>
  <div class="home">
    <div class="mobileNav">
      <div class="top">
         <a href="/"><img src="/Logo-Noir.png" alt="Logo DOOVER"></a>
        <div class="menu-btn"><span class="material-symbols-outlined" v-on:click="openMenu">menu</span></div>
      </div>
      <div class="search">
        <searchCard @son="setSon" :newsearch="search" :forPlaylist="res" />
      </div>
    </div>
    <div class="nav" :class="{ open: isMenuOpen }" >
      <div class="top">
          <a href="/"><img src="/Logo-Noir.png" alt="Logo DOOVER"></a>
          <div class="menu-btn"><span class="material-symbols-outlined" v-on:click="closeMenu">close</span></div>
      </div>
      <div class="explain">
        <h3>SEARCH A SONG YOU LIKE</h3>
        <h2>FIND A THOUSAND MORE</h2>
        <!-- <p>Discover doover.studio, your intelligent music companion connected to Spotify. Our app offers personalized music suggestions based on a song of your choice. Whether you're looking to explore new musical horizons or dive deeper into your tastes, doover.studio guides you on a tailored sonic journey. Let us enrich your listening experience by offering tracks that resonate with your unique style.</p> -->
      </div>
      <div class="search">
        <searchCard @son="setSon" :newsearch="search" :forPlaylist="res" />
      </div>
      <div class="menu-links">
        <ul>
          <li><p v-on:click="logout"><span class="material-symbols-outlined">logout</span> Log out</p></li>
        </ul>
      </div>
      <div class="your-playlist">
        <listPlaylist :reload="success" @playlistID="loadUserPlaylist"></listPlaylist>
      </div>
    </div>
    <div class="results">
      <div v-if="this.songToPlay" class="background-cover">
        <img id="bg-image" v-if="this.isBgVisible" :src="this.songToPlay.album.images[1].url" alt="">
      </div>
      <div class="inner-response">
        <responseCard
          @recherche="setRecherche"
          :chanson="song"
          :pos="index"
          v-for="(song, index) in res"
          :key="song.name"
          @currentlyPlayed="songCurrentlyPlayed"
          @addToSearch="songToSearch"
        />
        <div class="responseSpacer"></div>
      </div>
      <audioPlayer @addPlaylistToQueue="addToQueue" @addPlaylist="createPlaylist" :songPlayed="songToPlay" :isPlaylistCreatable="canCreatPlaylist" :isQueueCreatable="canAddToQueue"></audioPlayer>
    </div>
    <SuccessPopup :playlistUrl="newPlaylistUrl" :show="success" v-if="success"></SuccessPopup>
  </div>
</template>

<script>
import searchCard from "@/components/searchCard.vue";
import responseCard from "@/components/responseCard.vue";
import SuccessPopup from '@/components/SuccessPopup.vue';
import listPlaylist from '@/components/listPlaylist.vue';
import audioPlayer from '@/components/audioPlayer.vue';
import DcoverAPI from "../../src/api/dcoverAPI";

export default {
  name: "Home-view",
  components: {
    searchCard,
    responseCard,
    SuccessPopup,
    listPlaylist,
    audioPlayer,
  },
  data() {
    return { 
      res: "",
      search: "",
      success: false,
      isMenuOpen: false,
      songToPlay: "",
      addPlaylist: false,
      currentSong: "",
      canCreatPlaylist: false,
      isBgVisible: false,
      newPlaylistUrl: '',
      canAddToQueue: false,
    };
  },
  mounted() {
    this.getLovedPlaylist();
  },
  methods: {
    // Définit la méthode utilisée par le payload pour mettre à jour la propriété data
    toogleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    openMenu() {
      this.isMenuOpen = true;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    async setSon(son) {
      this.currentSong = son;
      this.canCreatPlaylist = true;
      this.canAddToQueue = true;
      const dcoverAPI = new DcoverAPI();
      // let resultat = [];
      this.res = [];
      this.res = await dcoverAPI.getSimilar(son);
    },
    async setRecherche(recherche) {
      this.canCreatPlaylist = false;
      this.setSon(recherche);
      this.search = recherche.titre + " - " + recherche.artiste;
    },
    logout() {
      const dcoverAPI = new DcoverAPI();
      dcoverAPI.logout();
    },
    async getRandom() {
      const dcoverAPI = new DcoverAPI();
      if (!this.res) {
        this.res = []
        this.res = await dcoverAPI.getRandom();
      }
      await dcoverAPI.getUserInfo()
    },
    async getPlaylistByURL(id) {
      const dcoverAPI = new DcoverAPI();
      if (!this.res) {
        this.res = []
        this.res = await dcoverAPI.loadUserPlaylist(id);
      }
      await dcoverAPI.getUserInfo()
    },
    // isSuccess(success) {
    //   if(success) {
    //     this.success = true;
    //     setTimeout(() => {
    //       this.success = false;
    //     }, 2000); // 2000 millisecondes (2 secondes)
    //   }
    // },
    async songCurrentlyPlayed(currentlyPlayed) {
      this.songToPlay = currentlyPlayed;
      this.isBgVisible = false
      await this.$nextTick();
      this.isBgVisible = true
    },
    async createPlaylist(status) {
      const dcoverAPI = new DcoverAPI();
      if (status) {
        const result = await dcoverAPI.createPlaylist(this.currentSong, this.res);
        if (result) {
          this.newPlaylistUrl = result;
          this.success = true;
          this.canCreatPlaylist = false;
          setTimeout(() => {
            this.success = false;
          }, 5000); // 2000 millisecondes (2 secondes)
        }
      }
    },
    async addToQueue() {
      const dcoverAPI = new DcoverAPI();
      dcoverAPI.addPlaylistToQueue(this.currentSong, this.res);
      this.canAddToQueue = false;
    },
    async songRemoved(id) {
      if (!this.currentSong) {
        delete this.res[id];

        // Créer un nouvel objet pour réindexer les clés
        let newObj = {};
        let index = 0;

        for (let key in this.res) {
          if (Object.prototype.hasOwnProperty.call(this.res, key)) {
            newObj[index] = this.res[key];
            index++;
          }
        }

        this.res = newObj;
      }
    },
    async loadUserPlaylist(id) {
      const dcoverAPI = new DcoverAPI();
      this.res = await dcoverAPI.loadUserPlaylist(id)
    },
    async getLovedPlaylist() {
      const dcoverAPI = new DcoverAPI();
      this.res = await dcoverAPI.getLovedPlaylist();
    },
    async songToSearch(song) {
      this.search = song;
      await this.setSon([song]);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100svh;
  text-align: justify;
  display: flex;
}
.nav {
  width: 40%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .menu-btn {
    display: none;
  }

  .menu-links {
    width: 75%;
    margin-top: 50px;

    li {
      cursor: pointer;
      text-decoration: none;
      font-weight: 700;
      color: black;
      text-transform: uppercase;

      p {
        display: flex;
        align-items: center;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}
.nav .top {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}
.nav .top img {
  width: 30%;
}
.explain {
  width: 75%;
  margin-bottom: 50px;
}
.search {
  width: 75%;
}
.mobileNav {
  display: none;
}

.results {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  .background-cover {
    position: absolute;
    height: 100svh;
    width: 100%;
    z-index: -5;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 992px) {
      height: 86svh;
      overflow-x: hidden;
    }
    
    img {
      animation: fadeIn 0.3s;
      min-width: 100%;
      min-height: 100%;
      aspect-ratio: 1 / 1;
      z-index: -10;
      position: absolute;
      filter: blur(16px);
    }
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }
}
.results .inner-response {
  width: 80%;
  height: 70svh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
  margin-bottom: 50px;
  align-content: flex-start;
  @media screen and (max-width: 1300px) {
    justify-content: center;
  }
}

.your-playlist {
  position: absolute;
  bottom: 50px;
  width: 75%;
}
.responseSpacer {
  display: none;
}
/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .home {
    display: block;
    height: auto;
  }
  .nav {
    width: 100%;
    height: 100svh;
    top: 0px;
    padding-top: 10px;
    position: absolute;
    transform: translateX(-101%);
    background-color: white;
    z-index: 99;
    transition: 0.5s;
    .menu-btn {
      display: block;
    }

    .menu-links {
      width: 90%;
    }
  }
  .nav.open {
    transform: translateX(0%);
  }
  .nav .top {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .nav .top img {
    width: 30%;
  }
  .explain {
    display: none;
  }
  .nav .search {
    display: none;
  }
  .mobileNav {
    width: 100%;
    margin-bottom: 25px;
    display: block;
  }
  .mobileNav .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px auto;
  }
  .mobileNav .top img {
    width: 30%;
  }
  .search {
    margin: auto;
  }

  .your-playlist {
    width: 90%;
  }

  .results {
    width: 100%;
    position: unset;
  }
  .results .inner-response {
    width: 90%;
    height: 85svh;
    position: relative;
    display: block;
    margin-bottom: 0px;
  }
  .responseSpacer {
    display: block;
    height: 120px;
    width: 100%;
  }
}

</style>
